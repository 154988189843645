import React, { useEffect, useState } from 'react'
import bg from "../Images/poster2.jpg"
import { maps } from '../Components/Maps'
import { Card } from '../Components/Card'
import { useSearchParams } from 'react-router-dom'

export const MapBan = () => {
  const [query] = useSearchParams();
  const [numMaps, setNumMaps] = useState<number>(7);
  const [isBan, setBan] = useState<boolean>(true);
  const mode = query.get("mode") || "BO1";
  const team1 = query.get("team1") || "Team 1";
  const team2 = query.get("team2") || "Team 2";
  //const [mode, setMode] = useState<String>("bo1");
  //const [team1, setTeam1] = useState<String>("Team 1");
  //const [team2, setTeam2] = useState<String>("Team 2");
 

  useEffect(() => {
    if (mode === "BO5") {
      numMaps < 4 ? setBan(true): setBan(false);
    } else if (mode === "BO3") {
      (numMaps > 5 || numMaps < 4) ? setBan(true): setBan(false);
    } else {
      numMaps > 1 ? setBan(true): setBan(false);
    }
  }, [query, mode, numMaps])

  const [currentTeam, setCurrentTeam] = useState<String>(team1);

  const changeTeam = () => {
    currentTeam === team1 ? setCurrentTeam(team2): setCurrentTeam(team1);
  };

  return (
    <div className="container" style={{backgroundImage: `url(${bg})`}}>
      <div className="card-container">
        {numMaps < 2? <h1>Map selection</h1>:<h1>{currentTeam} can {isBan ? "ban now": "select now"}</h1>}
        <div className="card-grid">

          {maps.map(x => {
            return (
              <Card key={x.name} data={x} mode={mode} isBan={isBan} setnumMaps={setNumMaps} numMaps={numMaps} changeTeam={changeTeam} team={currentTeam}
                team1={team1} team2={team2} />
            )
          })}
        </div>
      </div>
    </div>
  )
}
