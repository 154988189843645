import React, { useState } from 'react'
import Joi from 'joi';
import { ToastContainer } from 'react-toastify';
import { backend_url } from '../Components/Routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleError } from '../Components/Toasts';
import bg from '../Images/poster4.jpg';


export const SignIn = () => {
  const [query] = useSearchParams();
  const args = query.get("q") ? `?q=${query.get("q")}`: ""
  const [email, setEmail] = useState<String>("");
  const [password, setPassword] = useState<String>("");
  const [remember, setRemember] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLogged, setLogged] = useState<boolean>(false);
  const navigate = useNavigate();
  const buttonClick = () => {
    const schema = Joi.object({
      email: Joi.string().email({tlds: {allow: ["com", "dev", "net"]}}).required(),
      password: Joi.string().min(6).required(),
    })
    const {error, value} = schema.validate({email: email.replaceAll(" ", ""), password});
    if (error) {
      handleError(error.message.replaceAll("\"", ""));
    } else {
      if (!isLogged) {
        fetch(`${backend_url}/api/getUser`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams(value)
        }
        ).then(res => res.json())
        .then(data => {
          if (data.success) {
            console.log(remember);
            localStorage.setItem("user", data.token);
            navigate("/adminpanel" + args);
            window.location.reload();
            setLogged(true);
          } else {
            handleError(data.message);
          }
        });
      }
    }
  }
  return (
    <div className="container" style={{backgroundImage: `url(${bg})`}}>
      <div className="login-container">
        <h1>Sign In</h1>
        <form action="" className="login-form">
          <div className="text-base">
            <span>Email *</span>
            <input type="text" placeholder="email" onChange={(e) => setEmail(e.target.value)} 
              style={{borderColor: email === ""? "var(--secondary)": "var(--menu-text-color)"}} />
          </div>
          <div className="text-base">
            <span>Password *</span>
            <input type={showPassword ? "text": "password"} placeholder="password" onChange={(e) => setPassword(e.target.value)} 
              style={{borderColor: password === ""? "var(--secondary)": "var(--menu-text-color)"}} />
          </div>
        </form>
        <div className="optional">
          <div className="option">
            <input type="checkbox" id="remember" onChange={(e) => setRemember(e.target.checked)}/>
            <span>Remember me</span>
          </div>
          
          <div className="option">
            <input type="checkbox" id="showPassword"onChange={(e) => setShowPassword(e.target.checked)} />
            <span>Show Password</span>
          </div>
        </div>
        <button onClick={() => buttonClick()}>Login</button>
      </div>
      <ToastContainer />
    </div>
  )
}
