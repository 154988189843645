import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Players } from '../Models/Models';
import { backend_url } from '../Components/Routes';
import bg from '../Images/poster4.jpg';

export const Leaderboard = () => {
  const [query] = useSearchParams();
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [name, setName] = useState<string>("Tournament");
  const [players, setPlayers] = useState<Players[]>([{name: "", team: "", rank: "", kills: "", deaths: ""}]);
  useEffect(() => {
    const tournament = query.get("q");
    const fetchData = async () => {
      if (tournament) {
        const req = await fetch(`${backend_url}/api/tournaments`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            tournament: tournament
          })
        });
        const res = await req.json();
        if (res.success && res.data) {
          if (res.data.teams) {
            setPlayers(res.data.players);
            setName(res.data.name);
            setLoaded(true);
          };
        }
      }  
    };

    if (tournament && !isLoaded) {
      fetchData();
    }
  }, [isLoaded, query]);
  return (
    <div className="container" style={{backgroundImage: `url(${bg})`}}>
      <div className="table-container">
        <h1>{name} Player List</h1>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Team</th>
              <th>Rank</th>
              <th>Kills</th>
              <th>Deaths</th>
              <th>K/D</th>
            </tr>
          </thead>
          <tbody>
            {
              players.map(x => {
                return (
                  <tr key={x.name}>
                    <td>{x.name}</td>
                    <td>{x.team}</td>
                    <td>{x.rank}</td>
                    <td>{x.kills}</td>
                    <td>{x.deaths}</td>
                    <td>{Number(x.kills) - Number(x.deaths)}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
