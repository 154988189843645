import { toast } from "react-toastify";

export const handleError = (msg: String) => {
    toast.error(msg, {
      theme: "dark",
    });
}

export const handleSuccess = (msg: String) => {
  toast.success(msg, {
    theme: "dark"
  })
}