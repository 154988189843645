import React, { useEffect, useState } from 'react'
import bg from '../Images/poster.jpg';
import { CoinContainer } from '../Components/CoinContainer';
import { useSearchParams } from 'react-router-dom';
import { backend_url } from '../Components/Routes';

var teams = [
  {
    name: "Team 1",
  },
  {
    name: "Team 2",
  },
  {
    name: "Team 3",
  },
];

export const Home = () => {
  const [query] = useSearchParams();
  const args = query.get("q") ? `q=${query.get("q")}`: "";
  useEffect(() => {
    const tournament = query.get("q");
    const fetchData = async () => {
      if (tournament) {
        const req = await fetch(`${backend_url}/api/tournaments`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            tournament: tournament
          })
        });
        const res = await req.json();
        if (res.success && res.data) {
          if (res.data.teams) {
            teams = res.data.teams;
          }
        }
      }  
    }

    fetchData();
  }, [query]);

  const [list1, setList1] = useState<String>(teams[0].name);
  const [list2, setList2] = useState<String>(teams[1].name);
  const [action1, setAction1] = useState<boolean>(false);
  const [action2, setAction2] = useState<boolean>(false);

  const actionList = (list: String, team: String) => {
    if (list === "list1") {
      setList1(team);
      setAction1(!action1);
    } else {
      setList2(team);
      setAction2(!action2);
    }
  }

  return (
    <div className="container" style={{backgroundImage: bg, backgroundSize: "cover"}}>
      <div className="dropdown-container">
        <div className="dropdown-item">
          <h3>Select Team 1</h3>
          <button onClick={() => setAction1(!action1)}>
            <span>{list1}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" height="18"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
          </button>
          <div className="droplist" style={{display: action1 ? "flex": "none"}}>
            {teams.map(x => {
              return (<span key={`list1${x.name}`} className="list-item" onClick={() => actionList("list1", x.name)}>{x.name}</span>)
            })}
          </div>
        </div>
        <div className="dropdown-item">
          <h3>Select Team 2</h3>
          <button onClick={() => setAction2(!action2)}>
            <span>{list2}</span>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" height="18"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
          </button>

          <div className="droplist" style={{display: action2 ? "flex": "none"}}>
            {teams.map(x => {
              return (<span key={`list2${x.name}`} className="list-item" onClick={() => actionList("list2", x.name)}>{x.name}</span>)
            })}
          </div>
        </div>
      </div>
      <CoinContainer team1={list1} team2={list2} args={args}/>
    </div>
  )
}
