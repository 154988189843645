import React, { useState } from 'react'
import { maps } from './Maps'

interface CardProps {
    data: typeof maps[0],
    mode: String,
    numMaps: number,
    setnumMaps: any,
    isBan: boolean,
    changeTeam: any,
    team: String,
    team1: String,
    team2: String
}

const actionRef = {
  teamName: "Team 1",
  action: "select"
}

export const Card: React.FC<CardProps> = ({data, mode, numMaps, setnumMaps, isBan, changeTeam, team, team1, team2}) => {
  const [action, setAction] = useState<boolean>(false);
  const [actionTeam, setActionTeam] = useState<typeof actionRef>();
  const [side, setSide] = useState<String>("");
  const buttonClicker = () => {
    setAction(true);
    setnumMaps(numMaps - 1);
    //isBan ? setSelected(false): setSelected(true);
    setActionTeam({teamName: team.toString(), action: isBan ? "ban": "select"});
    changeTeam();
  };

  const selectionTeam = (currentTeam: String) => {
    if (currentTeam === team1) {
      return team2;
    }
    return team1;
  }

  return (
    <div key={data.name} className="card" style={{backgroundImage: `url(${data.img})`, filter: actionTeam?.action === "ban" ? "grayscale(80%)": "contrast(110%)"}}>
        <div className="card-head">{data.name}</div>
        { action ? (actionTeam?.action === "ban" ? <div className="card-body">Banned by {actionTeam?.teamName}</div> :
            (
              side === "" ?
              (<div className="inner-buttons">
                <h3>{selectionTeam(actionTeam?.teamName || "")} can choose</h3>
                <button onClick={() => setSide("attack")}>Attack</button>
                <button onClick={() => setSide("defence")}>Defend</button>
              </div>):
              <div className="card-body">{selectionTeam(actionTeam?.teamName || "")} : {side}</div>
            )
          ) : 
          (numMaps < 2 && side === ""?
            (<div className="inner-buttons">
              <h3>{team} can choose</h3>
              <button onClick={() => setSide("attack")}>Attack</button>
              <button onClick={() => setSide("defence")}>Defend</button>
            </div>): 
            (side === ""? <br /> :<div className="card-body">{team} : {side}</div>)
            )}
        { (action || numMaps < 2) ? <br />: 
          <button onClick={() => buttonClicker()}>{isBan ? "BAN": "SELECT"}</button> }
    </div>
  )
}
