import React from 'react'
import { Team } from '../Models/Models'
import Joi from 'joi';
import { handleError, handleSuccess } from './Toasts';
import { backend_url } from './Routes';
import { sortArray } from '../Utils/Utils';

interface Props {
    teams: Team[],
    setTeams: React.Dispatch<React.SetStateAction<Team[]>>,
    args: string,
    id: String
}

export const TeamCards: React.FC<Props> = ({teams, setTeams, args, id}) => {
    const handleChange = (value: string | number, teamIndex: number, field: keyof Team) => {
        const updatedTeams = teams.map((team, ti) => 
          ti === teamIndex 
            ? {...team, [field]: value} 
            : team
        );
        console.log(updatedTeams)
        setTeams(updatedTeams);
    };

    const saveButton = async () => {
        try {
            const schema = Joi.object({
                teams: Joi.array().min(2).max(10).items(
                    Joi.object({
                        name: Joi.string().required(),
                        totalMatches: Joi.string().required(),
                        won: Joi.string().required(),
                        loss: Joi.string().required(),
                        roundDiff: Joi.string().required()
                    })
                )
            });

            const {error, value} = schema.validate({teams});
            if (error) {
                if (error.message.startsWith("\"teams[")) {
                    const match = error.message.match(/\d+/);
                    const index = match ? parseInt(match[0]) + 1: 1;
                    handleError(`Team ${index} can't be empty`)
                } else {
                    handleError(error.message.replaceAll("\"", ""));
                } 
            } else {
                const sortedTeams = sortArray(value.teams);
                const req = await fetch(`${backend_url}/api/updateTeams`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: new URLSearchParams({
                        id: id.toString(),
                        teams: JSON.stringify(sortedTeams)
                    })
                });

                const res = await req.json();
                if (res.success) {
                    handleSuccess(res.message);
                } else {
                    handleError(res.message);
                };
            }
        } catch (err) {
            console.log(err)
            handleError("An error occured");
        }
    };

  return (
    <>
    {
        teams.map((team, index) => {
            return(
                <div key={team.name} className="panel">
                    <div className="panel-field">
                        <p>Team Name </p>
                        <input className="input-text" type="text" id={team.name} placeholder={team.name} value={team.name} onChange={(e) => handleChange(e.target.value, index, "name")}/>
                    </div>
                    <div className="panel-field">
                        <p>Matches Played </p>
                        <input className="input-text" type="text" placeholder="Total matches" id={`${team.name} total`} value={team.totalMatches} onChange={(e) => handleChange(e.target.value, index, "totalMatches")}/>
                    </div>
                    <div className="panel-field">
                        <p>Won </p>
                        <input className="input-text" type="text" placeholder="Won" id={`${team.name} won`} value={team.won} onChange={(e) => handleChange(e.target.value, index, "won")}/>
                    </div>
                    <div className="panel-field">
                        <p>Loss </p>
                        <input className="input-text" type="text" placeholder="Loss" id={`${team.name} loss`} value={team.loss} onChange={(e) => handleChange(e.target.value, index, "loss")}/>
                    </div>
                    <div className="panel-field">
                        <p>Round Diff </p>
                        <input className="input-text" type="text" placeholder="Round Difference" id={`${team.name} roundDiff`} value={team.roundDiff} onChange={(e) => handleChange(e.target.value, index, "roundDiff")}/>
                    </div>
                </div>
            )
        })
    }

        <div className="toss-button">
            <button className="prop" onClick={() => saveButton()}>Save</button>
            <a href={`/adminpanel${args}`} className="prop">Edit Players</a>
        </div>
    </>
  )
}
