import ascent from "../Images/ascent.png";
import abyss from "../Images/abyss.png";
import bind from "../Images/bind.png";
import haven from "../Images/haven.png";
import icebox from "../Images/icebox.png";
import lotus from "../Images/lotus.png";
import sunset from "../Images/sunset.png";


export const maps = [
    {
        name: "Ascent",
        img: ascent,
    },
    {
        name: "Abyss",
        img: abyss,
    },
    {
        name: "Bind",
        img: bind,
    },
    {
        name: "Haven",
        img: haven,
    },
    {
        name: "Icebox",
        img: icebox,
    },
    {
        name: "Lotus",
        img: lotus,
    },
    {
        name: "Sunset",
        img: sunset,
    },
]