import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NavBar } from './Components/NavBar';
import { routes } from './Components/Routes';

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        {routes.map(x => {
          return <Route key={x.name} path={x.href} element={x.page} />
        })}
      </Routes>
    </>
  );
}

export default App;
