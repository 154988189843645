import { AdminPanel } from "../Pages/AdminPanel";
import { Home } from "../Pages/Home";
import { Leaderboard } from "../Pages/Leaderboard";
import { MapBan } from "../Pages/MapBan";
import { SignIn } from "../Pages/SignIn";
import { Teams } from "../Pages/Teams";

const routes = [
    {
        name: "home",
        href: "/",
        page: <Home />,
    },
    {
        name: "map ban",
        href: "/mapban",
        page: <MapBan />,
    },
    {
        name: "teams",
        href: "/teams",
        page: <Teams />,
    },
    {
        name: "leaderboard",
        href: "/leaderboard",
        page: <Leaderboard />,
    },
    {
        name: "admin panel",
        href: "/adminpanel",
        page: <AdminPanel />,
    },
    {
        name: "sign in",
        href: "/login",
        page: <SignIn />,
    }
]

export const backend_url = "https://backend.anandps002.workers.dev";

export {routes};
