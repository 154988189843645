import { Players, Team } from '../Models/Models';

export const sortArray = (array: Team[] | Players[]): Team[] | Players[] => {
    let updatedArray: Team[] | Players[];
    
    if ("kills" in array[0]) {
        updatedArray = (array as Players[]).sort((a, b) => {
            const akills = Number(a.kills);
            const adeaths =  Number(a.deaths);
            const bkills = Number(b.kills);
            const bdeaths = Number(b.deaths);
            const akd = akills - adeaths;
            const bkd = bkills - bdeaths;
            if (akills === bkills) {
                return bkd - akd;
            }
            return bkills - akills;
        })
    } else {
        updatedArray = (array as Team[]).sort((a, b) => {
            if (Number(a.won) === Number(b.won)) {
                return (Number(b.roundDiff) - Number(a.roundDiff) );
            }
            return Number(b.won) - Number(a.won);
        })
    }
    return updatedArray;
};
