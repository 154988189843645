import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { backend_url } from '../Components/Routes';
import { CreateTournament } from '../Components/CreateTournament';
import { Players, Team } from '../Models/Models';
import { TeamCards } from '../Components/TeamCards';
import { PlayersCards } from '../Components/PlayersCards';
import { ToastContainer } from 'react-toastify';

export const AdminPanel = () => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const mode = query.get("mode") ? `mode=${query.get("mode")}`: "teams";
    const args = query.get("q") ? `?q=${query.get("q")}&mode=${mode}`: `?${mode}`;
    const [teams, setTeams] = useState<Team[]>([{name: "", totalMatches: "0", won: "0", loss: "0", roundDiff: "0"}]);
    const [players, setPlayers] = useState<Players[]>([{name: "", team: "", rank: "", kills: "0", deaths: "0"}]);
    const [logged, setLogged] = useState<boolean>(false);

    useEffect(() => {
        const token = localStorage.getItem("user");
        const tournament = query.get("q");
        const fetchLoginData = async () => {
            const req = await fetch(`${backend_url}/api/validateToken`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const res = await req.json();
            if (!res.success) {
                localStorage.removeItem("user");
                navigate("/login" + args);
                window.location.reload();
            }
        };

        const fetchData = async () => {
            if (tournament) {
              const req = await fetch(`${backend_url}/api/tournaments`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                  tournament: tournament
                })
              });
              const res = await req.json();
              if (res.success && res.data) {
                if (res.data.teams) {
                  setTeams(res.data.teams);
                  setLogged(true);
                };

                if (res.data.players) {
                  setPlayers(res.data.players);
                }
              }
            }  
          };

      
        if (token) {
            fetchLoginData();
            if (!logged) {
              fetchData();
            }
        } else {
            navigate("/login" + args);
            window.location.reload();
        }

    })
  return (
    <div className="container">
        {!query.get("q") ? <CreateTournament teams={teams}  setTeams={setTeams}/>:
        (
          <>
          <div className="coin-container">
            {query.get("mode") && query.get("mode") === "players"?
              <>
              <h2>Players</h2>
              <PlayersCards players={players} setPlayers={setPlayers} args={`?q=${query.get("q")}&mode=teams`} id={query.get("q") || ""} />
              </>:
              <>
                <h2>Teams</h2>
                <TeamCards teams={teams} setTeams={setTeams} args={`?q=${query.get("q")}&mode=players`} id={query.get("q") || ""}/>
              </>}
          </div>
          </>
        )}
        <ToastContainer />
    </div>
  )
}
