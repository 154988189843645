import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Team } from '../Models/Models';
import { backend_url } from '../Components/Routes';

export const Teams = () => {
  const [query] = useSearchParams();
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [name, setName] = useState<string>("Tournament");
  const [teams, setTeams] = useState<Team[]>([{name: "", totalMatches: "0", won: "0", loss: "0", roundDiff: "0"}]);
  useEffect(() => {
    const tournament = query.get("q");
    const fetchData = async () => {
      if (tournament) {
        const req = await fetch(`${backend_url}/api/tournaments`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            tournament: tournament
          })
        });
        const res = await req.json();
        if (res.success && res.data) {
          if (res.data.teams) {
            setTeams(res.data.teams);
            setName(res.data.name);
            setLoaded(true);
          };
        }
      }  
    };

    if (tournament && !isLoaded) {
      fetchData();
    }
  }, [isLoaded, query]);
  return (
    <div className="container">
      <div className="table-container">
        <h1>{name} Teams</h1>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Matches Played</th>
              <th>Win</th>
              <th>Loss</th>
              <th>Round Diff</th>
            </tr>
          </thead>
          <tbody>
            {
              teams.map(x => {
                return (
                  <tr key={x.name}>
                    <td>{x.name}</td>
                    <td>{x.totalMatches}</td>
                    <td>{x.won}</td>
                    <td>{x.loss}</td>
                    <td>{x.roundDiff}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
