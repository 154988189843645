import React from 'react'
import { Players } from '../Models/Models'
import Joi from 'joi';
import { handleError, handleSuccess } from './Toasts';
import { backend_url } from './Routes';
import { sortArray } from '../Utils/Utils';

interface Props {
    players: Players[],
    setPlayers: React.Dispatch<React.SetStateAction<Players[]>>,
    args: string,
    id: String
}

export const PlayersCards: React.FC<Props> = ({players, setPlayers, args, id}) => {
    const addPlayer = () => {
        setPlayers(players.concat({name: "", team: "", rank: "", kills: "0", deaths: "0"}))
    };

    const removePlayer = (index: number) => {
        if (players.length > 1) {
            const updatedPlayers = players.filter((_, i) => index !== i);
            setPlayers(updatedPlayers);
        }
    };

    const handleChange = (index: number, key: string, value: string) => {
        const updatePlayers = players.map((player, playerIndex)=> {
            if (index === playerIndex) {
                return {
                    ...player,
                    [key]: value
                };
            }
            return player;
        });

        setPlayers(updatePlayers);
    }

    const savePlayers = async () => {
        const schema = Joi.object({
            players: Joi.array().items(Joi.object({
                name: Joi.string().required(),
                team: Joi.string().required(),
                rank: Joi.string().required(),
                kills: Joi.string().required(),
                deaths: Joi.string().required()
            }))
        })

        const {error, value} = await schema.validate({players});
        if (error) {
            if (error.message.startsWith("\"players[")) {
                const match = error.message.match(/\d+/);
                const index = match ? parseInt(match[0]) + 1: 1;
                handleError(`Player ${index} can't be empty`)
            } else {
                handleError(error.message.replaceAll("\"", ""));
            } 
        } else {
            const sortedPlayers =  sortArray(value.players);
            const req = await fetch(`${backend_url}/api/updatePlayers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams({
                    id: id.toString(),
                    players: JSON.stringify(sortedPlayers)
                })
            });

            const res = await req.json()
            if (res.success) {
                handleSuccess(res.message);
                window.location.reload();
            } else {
                handleError(res.message);
            }
        }
    };


  return (
    <>
    <div className="player-panel">
        <div className="field-section">
            <div key="section-name" className="panel-field">
                <p>Name</p>
                <p>Team</p>
                <p>Rank</p>
                <p>Kills</p>
                <p>Deaths</p>
            </div>
            {players.map((player, index) => {
                return (
                    <div key={`player ${index}`} className="panel-field">
                        <input type="text" className="input-text" id={`name ${index}`} value={player.name} placeholder={`Player ${index + 1}`} onChange={(e) => handleChange(index, "name", e.target.value)}/>
                        <input type="text" className="input-text" id={`team ${index}`} value={player.team} placeholder="Team" onChange={(e) => handleChange(index, "team", e.target.value)}/>
                        <input type="text" className="input-text" id={`rank ${index}`} value={player.rank} placeholder="Rank" onChange={(e) => handleChange(index, "rank", e.target.value)}/>
                        <input type="text" className="input-text" id={`kills ${index}`} value={player.kills} placeholder="Kills" onChange={(e) => handleChange(index, "kills", e.target.value)}/>
                        <input type="text" className="input-text" id={`deaths ${index}`} value={player.deaths} placeholder="Deaths" onChange={(e) => handleChange(index, "deaths", e.target.value)}/>
                        <span onClick={() => removePlayer(index)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ fill: (players.length > 1 ? "var(--red)" : "var(--bg-color)") }}>
                                <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z" />
                            </svg>
                        </span>
                    </div>
                )
            })}
        </div>
    </div>
    <div className="toss-button">
        <button className="prop" onClick={() => addPlayer()}>Add Player</button>
    </div>
    <div className="toss-button">
        <button className="prop" onClick={() => savePlayers()}>Save</button>
        <a href={`/adminpanel${args}`} className="prop">Edit Teams</a>
    </div>
    </>
  )
}
