import React, { MouseEvent, useEffect, useState } from 'react'
import logo  from '../Images/logo512.png'
import { Sling as SideMenu } from 'hamburger-react'
import { routes } from './Routes';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const NavBar = () => {
    const [query] = useSearchParams();
    const args = query.get("q") ? `?q=${query.get("q")}`: ""
    const [isToggled, setToggled] = useState<boolean>(false);
    const [loginData, setLoginData] = useState<String>();
    const navigate = useNavigate();
    useEffect(() => {
        const login = localStorage.getItem("user");
        setLoginData(login || "")
    }, [])

    const logOutButton = (e: MouseEvent) => {
        e.preventDefault();
        localStorage.removeItem("user");
        navigate("/" + args);
        window.location.reload();
    }
  return (
    <div className="navbar">
        <div className="nav-item">
            <a href={`/${args}`}>
                <img src={logo} alt='logo' />
            </a>
            <a href={`/${args}`}><h3>Valorant tools</h3></a>
        </div>
        <div className="nav-item">
            <SideMenu toggled={isToggled} toggle={setToggled} size={24}/>
            <div className='nav-menu' style={{display: isToggled? "flex": "none"}}>
                {routes.map(x => {
                    return ((x.name === "admin panel" && loginData === "") ?  null:
                     (x.name === "sign in" && loginData !== ""? <a key="logout" href={`/${args}`} onClick={(e) => logOutButton(e)}>log out</a>:<a key={x.name} href={`${x.href}${args}`}>{x.name}</a>))
                })}
            </div>
        </div>
    </div>
  )
}
