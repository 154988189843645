import React, { useState } from 'react'

interface CoinProps {
  team1: String,
  team2: String,
  args: String
}

const banOptions = [
  {
    name: "Ban Map",
  },
  {
    name: "Choose side",
  },
];

const mapOptions = [
  {
    name: "BO1",
  },
  {
    name: "BO3",
  },
  {
    name: "BO5",
  },
];

export const CoinContainer: React.FC<CoinProps> = ({team1, team2, args}) => {
  const [isFilpped, setFlipped] = useState<boolean>(false);
  const [isRep, setRep] = useState<boolean>(false);
  const [prob, setProb] = useState<boolean>();
  const [list1, setList1] = useState<String>(banOptions[0].name);
  const [list2, setList2] = useState<String>(mapOptions[0].name);
  const [action1, setAction1] = useState<boolean>(false);
  const [action2, setAction2] = useState<boolean>(false);

  const flipCoin = () => {
    setFlipped(true);
    setTimeout(() => {
      const mProb = Math.floor(Math.random() * 5) + 1;
      setProb(mProb % 2 === 0 ? true: false);
      setFlipped(false);
      setRep(true);
    }, 2000)
  }

  const actionList = (list: String, team: String) => {
    if (list === "list1") {
      setList1(team);
      setAction1(!action1);
    } else {
      setList2(team);
      setAction2(!action2);
    }
  }

  const genLink = () => {
    let t1: String, t2: String;
    if (list1 === "Ban Map") {
      t1 = prob ? team1: team2;
      t2 = prob ? team2: team1;
    } else {
      t1 = prob ? team2: team1;
      t2 = prob ? team1: team2;
    }

    const link = `/mapban?mode=${list2}&team1=${t1}&team2=${t2}${args !== ""? `&${args}`: ""}`;
    return link;
  }

  return (
    <div className="coin-container">
        <h2>{!isFilpped && isRep? `${(prob ? team1: team2)} won the toss`: "Toss the coin"}</h2>
        <div className={`coin${isFilpped ? " flip": ""}`}>
            <span>{isFilpped ? "": (!isRep ? "": (prob ? team1: team2))}</span>
        </div>
        {!isRep ? 
          (<div className="toss-button">
            <button className="prop" onClick={() => flipCoin()}>Toss coin</button>
          </div>):
          (
            <>
            <div className="dropdown-container">
              <div className='dropdown-item'>
                <button onClick={() => setAction1(!action1)}>
                  <span>{list1}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" height="18"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                </button>
                <div className="droplist" style={{display: action1 ? "flex": "none"}}>
                  {banOptions.map(x => {
                    return (<span key={`list1${x.name}`} className="list-item" onClick={() => actionList("list1", x.name)}>{x.name}</span>)
                  })}
                </div>
              </div>

              <div className='dropdown-item'>
                <button onClick={() => setAction2(!action2)}>
                  <span>{list2}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" height="18"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                </button>
                <div className="droplist" style={{display: action2 ? "flex": "none"}}>
                  {mapOptions.map(x => {
                    return (<span key={`list2${x.name}`} className="list-item" onClick={() => actionList("list2", x.name)}>{x.name}</span>)
                  })}
                </div>
              </div>
            </div>
            <div className="toss-button">
              <button className="prop" onClick={() => flipCoin()}>Toss again</button>
              <a href={genLink()} className="prop">Go to map ban</a>
            </div>
            </>
          )
        }
    </div>
  )
}
