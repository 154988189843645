import Joi from 'joi';
import React, { useState } from 'react'
import { handleError, handleSuccess } from './Toasts';
import { ToastContainer } from 'react-toastify';
import { backend_url } from './Routes';
import { Team } from '../Models/Models';

interface Props {
    teams: Team[],
    setTeams: React.Dispatch<React.SetStateAction<Team[]>>
}


export const CreateTournament: React.FC<Props> = ({teams, setTeams}) => {
    const [name, setName] = useState<string>("");
    //const [teams, setTeams] = useState<Team[]>([{name: "", players: [{name: "", kills: 0}]}]);
    const [tId, setTId] = useState<string>("");
    const handleChange = (e: string, i: number) => {
        let data = teams;
        data[i].name = e;
        setTeams(teams);
        console.log(teams)
    }

    const buttonClick = () => {
        setTeams(teams.concat({name: "", totalMatches: "0", won: "0", loss: "0", roundDiff: "0"}));
    }

    const copyButton = () => {
        const el = document.getElementById("link")
        if (el) {
            // Create a temporary textarea element to copy the content
            const tempInput = document.createElement("textarea");
            let textToCopy = "";
            
            // If it's an input or textarea element, use its value, otherwise use its text content
            if (el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement) {
                textToCopy = el.value;
            } else {
                textToCopy = el.textContent || '';
            }
            
            document.body.appendChild(tempInput);
            tempInput.value = textToCopy;
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            
            handleSuccess("Link copied");
        } else {
            handleError("Element with ID 'link' not found.");
        }
    }
    
    const removeTeam = (i: number) => {
        if (teams.length > 1) {
            const updatedTeams = teams.filter((_, index) => index !== i)
            setTeams(updatedTeams);
        }
        
    }

    const createButton = () => {
        const schema = Joi.object({
            name: Joi.string().min(3).required(),
            teams: Joi.array().min(2).max(10).items(
                Joi.object({
                    name: Joi.string().required(),
                    totalMatches: Joi.string().required(),
                    won: Joi.string().required(),
                    loss: Joi.string().required(),
                    roundDiff: Joi.string().required()
                })
            )
        });

        const {error, value} = schema.validate({name, teams});
        if (error) {
            if (error.message.startsWith("\"teams[")) {
                const match = error.message.match(/\d+/);
                const index = match ? parseInt(match[0]) + 1: 1;
                handleError(`Team ${index} can't be empty`)
            } else {
                handleError(error.message.replaceAll("\"", ""));
            } 
        } else {
            fetch(`${backend_url}/api/regTournament`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams({
                    name: value.name,
                    teams: JSON.stringify(value.teams)
                })
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    if (data.success) {
                        setTId(data.data)
                    } else {
                        handleError(data.message);
                    }
                })
        }
    }
  return (
        <>
        <div className="coin-container">
            {tId === "" ?
                <><h2>Tournament Name *</h2>
                <input className="input-textbox" type="text" placeholder="name" id="Tournament name" value={name} onChange={(e) => setName(e.target.value)} /><div className='teams-section'>
                      <h2>Teams </h2>
                      {teams.map((x: typeof teams[0], i: number) => {
                          return (
                              <div className="team" key={`team ${i}`}>
                                  <input className="input-textbox" type="text" placeholder={`Team ${i + 1}`} id={`team ${i}`} onChange={(e) => handleChange(e.target.value, i)} />
                                  <span onClick={() => removeTeam(i)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ fill: (teams.length > 1 ? "var(--red)" : "var(--bg-color)") }}>
                                          <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z" />
                                      </svg>
                                  </span>
                              </div>
                          );
                      })}
                      <br />
                      <div className="toss-button">
                          <button className="prop" onClick={() => buttonClick()}>Add Team</button>
                          <button className="prop" onClick={() => createButton()}>Create</button>
                      </div>

                  </div></>:
                <>
                    <h2>Tournament created</h2>
                    <input id="link" name="link" type="text" className="input-textbox" value={`${window.location.origin.toString()}?q=${tId}`} />
                    <div className="toss-button">
                          <button className="prop" onClick={() => copyButton()}>Copy Url</button>
                          <a className="prop" href={`${window.location.href}?q=${tId}`}>Continue</a>
                    </div>
                </>
            }
        </div>
        <ToastContainer />
        </>
  )
}
